#aboutUs {
  padding: 48px 16px;

  .about-us {
    &__img {
      margin: 0 auto;
      width: 90%;

      &-container {
        position: relative;

        &:after {
          background: linear-gradient(to top, $primary, transparent);
          bottom: 0;
          content: "";
          height: 75%;
          left: 0;
          position: absolute;
          right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  #aboutUs {
    .about-us {
      &__content {
        margin-top: -100px;
        position: relative;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  #aboutUs {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 48px 30px;

    .about-us {
      &__content {
        max-width: calc(60% - 50px);
      }
    }
  }
}
