.nav {
  width: 100%;

  &__item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    border-right: 2px solid $primary;
    cursor: pointer;
    font-size: 20px;
    padding: 16px;
    text-align: center;
    transition: 0.2s ease-out;
    width: 100%;

    &--active,
    &:hover,
    &:focus {
      border-right: 2px solid $red;
      color: $red;
    }
  }
}
