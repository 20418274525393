/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-360deg);
  }
}

/* all other browsers */
@keyframes spinner {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
  }
  10% {
    -webkit-transform: translateY(0);
  }
  30% {
    -webkit-transform: translateY(-20px);
  }
  45% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-7px);
  }
  55% {
    -webkit-transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-20px);
  }
  45% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
  55% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    background: $heading;
    color: white;
  }
  10% {
    background: $heading;
    color: white;
  }
  50% {
    background: white;
    color: $heading;
    transform: scale(1, 1);
  }
  10% {
    background: $heading;
    color: white;
  }
}

@-webkit-keyframes ping {
  from {
    transform: scale(0.25);
    -ms-transform: scale(0.25);
    /* IE 9 */
    -webkit-transform: scale(0.25);
    /* Safari and Chrome */
    -o-transform: scale(0.25);
    /* Opera */
    -moz-transform: scale(0.25);
    /* Firefox */
    opacity: 1;
  }
  to {
    transform: scale(1);
    -ms-transform: scale(1);
    /* IE 9 */
    -webkit-transform: scale(1);
    /* Safari and Chrome */
    -o-transform: scale(1);
    /* Opera */
    -moz-transform: scale(1);
    /* Firefox */
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    opacity: 1;
    transform: scale(0.5);
  }
  50% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

@-webkit-keyframes bounce2 {
  92% {
    -webkit-transform: translateY(0%);
  }
  94% {
    -webkit-transform: translateY(-15%);
  }
  96% {
    -webkit-transform: translateY(0%);
  }
  97% {
    -webkit-transform: translateY(-7%);
  }
  98% {
    -webkit-transform: translateY(0%);
  }
  99% {
    -webkit-transform: translateY(-3%);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}

@keyframes bounce2 {
  92% {
    transform: translateY(0%);
  }
  94% {
    transform: translateY(-15%);
  }
  96% {
    transform: translateY(0%);
  }
  97% {
    transform: translateY(-7%);
  }
  98% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes ripple {
  0% {
    @include transform(scale(0));
    opacity: 1;
  }

  100% {
    @include transform(scale(1));
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    @include transform(scale(0.3));
    opacity: 1;
  }

  100% {
    @include transform(scale(1));
    opacity: 0;
  }
}

@-webkit-keyframes button-ripple {
  to {
    @include transform(scale(4));
    opacity: 0;
  }
}

@keyframes button-ripple {
  to {
    @include transform(scale(4));
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
