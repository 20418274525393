//Bootstrap container
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

body,
html {
  background-color: $primary;
  height: auto;
  min-height: 100vh;
  // overflow-x: hidden;
  position: relative;
}

* {
  box-sizing: border-box;
}

img {
  display: block;
  max-width: 100%;
}

.main-content {
  overflow-x: hidden;
  width: 100%;
}

.sidebar {
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  transition: 0.45s ease-in-out;
  width: 88%;
  z-index: 8;
}

.inner-content {
  p {
    margin-bottom: 30px;
    line-height: 1.5;
  }

  ul,
  li {
    list-style-type: circle;
  }

  ol {
    list-style-type: decimal;
  }
}

@media only screen and (max-width: 991px) {
  html {
    &.sidebar--open {
      .sidebar {
        transform: none;
      }
    }

    .sidebar {
      @include transform(translateX(-100%));
    }
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .main-content {
    margin-left: 24%;
    width: 76%;
  }

  .sidebar {
    width: 24%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
    // max-width: 1140px;
  }
}
