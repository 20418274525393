.sidebar {
  align-items: center;
  box-shadow: none;
  background-color: $primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 0;

  .sidebar--open & {
    box-shadow: 3px 0px 12px rgba(#292929, 0.3);
  }

  &-toggle {
    align-items: center;
    background-color: $red;
    border-radius: 4px;
    box-shadow: 0 3px 12px rgba($primary, 0.3);
    cursor: pointer;
    display: flex;
    font-size: 20px;
    justify-content: center;
    left: 16px;
    padding: 12px 16px;
    position: fixed;
    top: 16px;
    transition: 0.2s ease;
    z-index: 7;

    &:hover,
    &:focus {
      background-color: darken($red, 10%);
    }
  }

  &__close {
    cursor: pointer;
    font-size: 50px;
    font-weight: bold;
    line-height: 1;
    padding: 16px;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s ease;
    user-select: none;

    &:hover,
    &:focus {
      color: $red;
    }
  }

  &__logo {
    display: block;
    margin: 0 auto 56px;
    max-width: 160px;
  }

  .socials {
    color: $red;
    font-size: 32px;
    padding: 48px 16px 0;

    a {
      color: $red;
      margin: 0;
      transition: 0.2s ease;

      svg {
        margin: 0;
      }

      &:hover {
        color: lighten($red, 10%);
      }
    }
  }

  .button {
    margin-top: 24px;
  }
}

@media only screen and (min-width: 992px) {
  .sidebar {
    box-shadow: 3px 0px 12px rgba(#292929, 0.3);
    &-toggle,
    &__close {
      display: none;
    }
  }
}
