$font-default: "Montserrat", sans-serif;
$font-code: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;

body {
  color: $text;
  font-family: $font-default;
  font-size: 16px;
  line-height: 1.2;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: $font-code;
}

.heading {
  font-size: 1.75rem;
  font-weight: 900;
  margin-bottom: 30px;
  position: relative;
  text-transform: uppercase;

  &--centre {
    text-align: center;

    &:after {
      margin: 0 auto;
      right: 0;
    }
  }

  &:after {
    background-color: $red;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    top: 100%;
    width: 160px;
  }
}

@media only screen and (min-width: 992px) {
  .heading {
    font-size: 2.25rem;
  }
}
