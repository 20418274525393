.hero {
  align-items: center;
  background: url("../../images/hero-new.jpg") center/cover no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
  min-height: 500px;
  padding: 0 30px;
  padding-bottom: 15%;
  position: relative;
  width: 100%;

  &:before {
    background: linear-gradient(to top, $primary 0%, rgba($primary, 0) 100%);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__title {
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 24px;
    text-align: center;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

    span {
      color: $red;
    }
  }
}

@media only screen and (min-width: 992px) {
  .hero {
    &__title {
      font-size: 42px;
    }

    &__subtitle {
      font-size: 24px;
    }
  }
}
