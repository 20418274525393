.members-login {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;

  &__content {
    padding: 48px 32px;
    text-align: center;
  }

  &__logo {
    margin: 32px auto 16px;
    max-width: 200px;
  }

  &__title {
    font-size: 48px;
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 16px;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: bold;
  }
}

@media only screen and (min-width: 992px) {
  .members-login {
    &__title {
      font-size: 64px;
    }

    &__subtitle {
      font-size: 32px;
    }
  }
}
