#facilities {
  padding: 30px 0px 60px;
  text-align: center;

  .facilities {
    &__img {
      background: url("../../images/spartan-wall.jpeg") center/cover no-repeat;
      margin: 0 auto;
      max-height: 400px;
      padding-bottom: 100%;
      position: relative;
      width: 100%;
    }

    &__content {
      background-color: #292929;
      box-shadow: -7px 7px 21px rgba($primary, 0.3);
      margin: -100px 15px 0;
      padding: 48px 24px 32px;
      position: relative;
      z-index: 1;
    }
  }
}

@media only screen and (min-width: 992px) {
  #facilities {
    padding: 100px 30px;

    .facilities {
      &__img {
        background: url("../../images/spartan-wall.jpeg") center/cover no-repeat;
        margin: 0 auto;
        max-height: 400px;
        max-width: 80%;
        padding-bottom: 40%;
        position: relative;
        width: 100%;
      }

      &__content {
        background-color: #292929;
        box-shadow: -7px 7px 21px rgba($primary, 0.3);
        margin: 0 auto;
        max-width: 60%;
        padding: 50px 45px;
        position: relative;
        @include transform(translateY(-100px));
        z-index: 1;
      }
    }
  }
}
