a.button,
.button {
  align-items: center;
  background-color: $red;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: bold;
  justify-content: center;
  margin: 0;
  min-height: 50px;
  min-width: 150px;
  padding: 16px;
  transition: 0.3s ease;

  &:hover,
  &:focus {
    background-color: darken($red, 10%);
  }
}

a,
.link {
  color: $text;
  display: inline-block;
  font-weight: normal;
  margin: 32px 16px;
  text-decoration: none;
  transition: 0.3s ease;

  svg {
    margin-right: 8px;
    transition: 0.3s ease;
  }

  &:hover,
  &:focus {
    color: darken($text, 10%);

    svg {
      margin-right: 12px;
    }
  }
}
