#badBitches {
  padding: 30px 16px 30px;
}

@media only screen and (max-width: 991px) {
  #badBitches {
    .bb {
      &__img {
        &-container {
          position: relative;

          &:after {
            background: linear-gradient(to top, $primary, transparent);
            bottom: 0;
            content: "";
            height: 75%;
            left: 0;
            position: absolute;
            right: 0;
          }
        }
      }

      &__content {
        margin-top: -100px;
        position: relative;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  #badBitches {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 50px 50px 100px;
    position: relative;

    &:after {
      background: linear-gradient(to top, $primary, transparent);
      bottom: 0;
      content: "";
      height: 75%;
      left: 0;
      position: absolute;
      right: 0;
    }

    .bb {
      &__img {
        bottom: 0;
        height: 100%;
        max-width: 880px;
        left: 50%;
        margin: auto;
        position: absolute;
        top: 0;
      }

      &__content {
        max-width: 50%;
        position: relative;
        z-index: 1;
      }
    }
  }
}
